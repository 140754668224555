

































import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import { FormField } from '@/app_code/Forms'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { CreateEnumerationValueModel, EnumerationGroupModel, EnumerationValueModel, EnumValueType, UpdateCustomEnumValueModel } from 'truemarket-modules/src/models/api'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DataTable
  }
})
export default class Index extends Vue {
  isAdding = false

  isEditing = false
  editId: string | null = null

  groupInfo: EnumerationGroupModel | null = null

  vals: EnumerationValueModel[] | null = null

  addDef = [
    new FormField({ Key: 'EnumValue', Placeholder: 'E.g. 6', Label: 'Enumeration Value (must be unique)', Type: 'text' }),
    new FormField({ Key: 'EnumLabel', Placeholder: 'E.g. 6+ Bedrooms', Label: 'Enumeration Label', Type: 'text' })
  ]

  editDef: FormField[] | null = null

  private dtDef = new DataTableDef(new DataTableRowDef(row => row.CountryId, [
    new DataTableColDef('Value', row => row.Value),
    new DataTableColDef('Label', row => row.Label),
    new DataTableColDef('Parent', row => row.ParentId ? `${row.Parent.FormattedType} - ${row.Parent.Label}` : 'N/A')
  ]))

  loadValues () {
    if (!this.groupInfo) return

    Services.API.Enumerations.GetEnumValuesForType(this.groupInfo.Type, true).then((items) => {
      this.vals = items
    })
  }

  mounted () {
    const group = parseInt(this.$route.params.type) as EnumValueType

    Services.API.Enumerations.GetGroup(group).then((info) => {
      this.groupInfo = info

      this.loadValues()
    })
  }

  addItem () {
    const form = (this.$refs.addForm as Form)

    const model = form.GetValues().BuildModel<CreateEnumerationValueModel>()

    if (this.groupInfo) model.EnumValueType = this.groupInfo.Type

    Services.API.Enumerations.CreateValue(model).then(() => {
      this.loadValues()

      this.isAdding = false
    })
  }

  editItem (id: string) {
    this.isEditing = true
    this.editId = id

    Services.API.Enumerations.GetValue(id).then((val) => {
      this.editDef = [
        new FormField({ Key: 'EnumValue', Placeholder: 'E.g. 6', Label: 'Enumeration Value (must be unique)', Type: 'text', Value: val.Value }),
        new FormField({ Key: 'EnumLabel', Placeholder: 'E.g. 6+ Bedrooms', Label: 'Enumeration Label', Type: 'text', Value: val.Label })
      ]
    })
  }

  saveItem () {
    if (!this.editId) return

    const form = (this.$refs.editForm as Form)

    const model = form.GetValues().BuildModel<UpdateCustomEnumValueModel>()

    Services.API.Enumerations.UpdateValue(this.editId, model).then(() => {
      this.loadValues()

      this.isEditing = false
      this.editDef = null
      this.editId = null
    })
  }
}
